import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import filters from '@filters/filters';
import { formatPx2Rem } from '@utils/index';
import { isEmpty, dateFilter } from '@utils/utils';
import { statusMap } from '@/dictionaries/checkOut';
import { cancelCheckOut } from '@api/checkOut';
export default {
  name: 'CheckOutDetail',
  mixins: [common, filters],
  components: {},
  data() {
    return {
      headerTitle: '退房详情',
      clientInfo: {
        // 客户信息
        baseInfoFields: [
        // 基本信息
        {
          title: '房间号',
          key: 'roomNo',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_door'
          },
          color: 'default'
        }, {
          title: '租户姓名',
          key: 'customerName',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_name'
          },
          color: 'default'
        }, {
          title: '租户电话',
          key: 'customerPhone',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_phone'
          },
          color: 'default'
        }, {
          title: '提交时间',
          key: 'userCreateTime',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_time'
          },
          color: 'default'
        }],
        baseInfo: {}
      },
      statusMap,
      isLoading: false,
      // 是否在加载数据
      isRefreshPage: false,
      // 是否需要刷新页面
      noDataMessage: '',
      // 列表为空时的提示文案
      detailInfo: {} // 详情信息
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {},
  methods: {
    formatPx2Rem,
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter(data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty;
    },
    // 格式化基本信息
    formatBaseInfo() {
      let {
        detailInfo
      } = this;
      this.clientInfo.baseInfo = {
        roomNo: this.fieldEmptyFilter(detailInfo.roomNo),
        customerName: this.fieldEmptyFilter(detailInfo.customerName),
        customerPhone: this.fieldEmptyFilter(detailInfo.customerPhone),
        userCreateTime: this.fieldEmptyFilter(detailInfo.userCreateTime)
      };
    },
    handleDetail() {
      this.$router.push({
        name: 'CheckOutDetailInfo',
        query: {
          id: this.id,
          type: 'detail'
        }
      });
    },
    handleGoHome() {
      this.$router.replace({
        path: '/'
      });
    },
    handleGoPre() {
      this.$router.back();
    },
    // 取消退房申请
    handleCheckOut() {
      const {
        id
      } = this;
      this.$dialog.confirm({
        title: '提示',
        width: '300px',
        message: '请确认是否取消该退房申请'
      }).then(() => {
        cancelCheckOut({
          id
        }).then(res => {
          this.$router.back();
        });
      }).catch(() => {
        // on cancel
      });
    },
    handleCheck() {
      this.$router.push({
        name: 'CheckOutDetailInfo',
        query: {
          id: this.id,
          type: 'check'
        }
      });
    },
    async handleCallback({
      isError,
      errorMessage = undefined
    }) {
      // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!');
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },

    async getDetailData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 获取详情数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await this.$store.dispatch('checkOut/GetCheckOutDetail', {
          id
        });
        // let result = response.data
        let result = response;
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || {};
          this.detailInfo = resultData;
          this.formatBaseInfo();
          await this.handleCallback({
            isError: false
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.getDetailData();
    }
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    // this.$on('checkOutDetailUpdate', (val) => {
    //   this.initData()
    // })
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};