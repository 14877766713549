var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-flexbox cyui-detail-header"
        }, [_c('div', {
          staticClass: "cyui-flexbox-item cyui-detail-header-content"
        }, [_c('div', {
          staticClass: "cyui-detail-header-title"
        }, [_vm._v(" " + _vm._s(_vm.statusMap[_vm.detailInfo.status] && _vm.statusMap[_vm.detailInfo.status]['text']) + " ")]), _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("上门验房时间")]), _c('span', [_vm._v(_vm._s(_vm.detailInfo.checkTime || '-'))])]), _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("离店时间")]), _c('span', [_vm._v(_vm._s(_vm.dateFilter(_vm.detailInfo.leaveTime)))])])]), _c('div', {
          staticClass: "cyui-detail-header-extra"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [_c('svg-icon', {
          attrs: {
            "icon-class": "status_4"
          }
        })], 1)])]), _c('div', {
          staticClass: "cyui-section"
        }, [_c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('cyui-field-list-info', {
          attrs: {
            "fields": _vm.clientInfo.baseInfoFields,
            "data": _vm.clientInfo.baseInfo,
            "title-width": `${_vm.formatPx2Rem(140)}rem`
          }
        })], 1)])]), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 需求备注 ")])])]), _c('div', {
          staticClass: "cyui-card-body"
        }, [_c('div', {
          staticClass: "cyui-section-remark"
        }, [_c('div', {
          staticClass: "cyui-section-remark-content"
        }, [_c('p', [_vm._v("1.联系客户确认是否退房")]), _c('p', [_vm._v("2.等待工程部上门验房")]), _c('p', [_vm._v("3.核对退房信息")])])])])])])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [`${_vm.detailInfo.status}` === '0' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleCheckOut
          }
        }, [_vm._v(" 取消退房申请 ")])])]) : _vm._e(), `${_vm.detailInfo.status}` === '0' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleGoPre
          }
        }, [_vm._v(" 返回上一页 ")])])]) : _vm._e(), `${_vm.detailInfo.status}` === '1' || `${_vm.detailInfo.status}` === '2' || `${_vm.detailInfo.status}` === '3' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-info cyui-btn-block",
          attrs: {
            "type": "button"
          }
        }, [_vm._v(" 验房进行中 ")])])]) : _vm._e(), `${_vm.detailInfo.status}` === '4' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleCheck
          }
        }, [_vm._v(" 核对退房信息 ")])])]) : _vm._e(), `${_vm.detailInfo.status}` === '5' || `${_vm.detailInfo.status}` === '6' || `${_vm.detailInfo.status}` === '7' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleDetail
          }
        }, [_vm._v(" 查看详情 ")])])]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };